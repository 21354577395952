<template>
  <v-container fluid class="register-page">
    <v-row justify="center">
      <v-col cols="12" md="6" lg="4">
        <v-card class="register-card dark-card" outlined>
          <v-card-title class="text-center title">
            Inscription
          </v-card-title>

          <!-- Sélection du rôle -->
          <v-select
            v-model="selectedRole"
            :items="roles"
            label="Je suis"
            outlined
            required
            class="role-selector"
          ></v-select>

          <!-- Formulaire -->
          <v-form ref="form" v-model="valid" class="register-form">
            <!-- Informations générales -->
            <v-text-field
              v-model="formData.username"
              label="Nom d'utilisateur"
              :rules="[rules.required, rules.username]"
              outlined
              required
              class="custom-input dark-input"
            ></v-text-field>
            <v-text-field
              v-model="formData.email"
              label="Adresse e-mail"
              :rules="[rules.required, rules.email]"
              outlined
              required
              class="custom-input dark-input"
            ></v-text-field>
            <v-text-field
              v-model="formData.password"
              :type="showPassword ? 'text' : 'password'"
              label="Mot de passe"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="togglePasswordVisibility"
              :rules="passwordRules"
              outlined
              required
              class="custom-input dark-input"
            ></v-text-field>
            <v-text-field
              v-model="formData.confirmPassword"
              :type="showPassword ? 'text' : 'password'"
              label="Confirmer le mot de passe"
              :rules="[rules.required, rules.confirmPassword]"
              outlined
              required
              class="custom-input dark-input"
            ></v-text-field>

            <!-- Rôles spécifiques -->
            <template v-if="selectedRole === 'teacher'">
              <v-text-field
                v-model="formData.schoolName"
                label="Nom de l'école"
                :rules="[rules.required]"
                outlined
                required
                class="custom-input dark-input"
              ></v-text-field>
              <v-select
                v-model="formData.educationLevel"
                :items="educationLevels"
                label="Niveau d'enseignement"
                :rules="[rules.required]"
                outlined
                required
                class="custom-input dark-input"
              ></v-select>
            </template>

            <template v-if="selectedRole === 'student'">
              <v-text-field
                v-model="formData.accessCode"
                label="Code d'accès fourni par le professeur"
                :rules="[rules.required]"
                outlined
                required
                class="custom-input dark-input"
              ></v-text-field>
            </template>
          </v-form>

          <!-- Bouton d'inscription -->
          <v-card-actions>
            <v-btn
              :disabled="!valid"
              block
              color="red darken-3"
              class="register-button"
              @click="register"
            >
              S'inscrire
            </v-btn>
          </v-card-actions>

          <!-- Messages d'erreur et de succès -->
          <v-alert v-if="errorMessage" type="error" class="error-message">
            {{ errorMessage }}
          </v-alert>
          <v-alert v-if="verificationEmailSent" type="success" class="verification-message">
            Un e-mail de vérification a été envoyé à {{ formData.email }}. Veuillez vérifier votre boîte de réception.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedRole: null,
      roles: [
        { title: "Élève", value: "student" },
        { title: "Professeur", value: "teacher" },
        { title: "Utilisateur", value: "normal" },
      ],
      educationLevels: ["Primaire", "Collège", "Lycée", "Enseignement Supérieur"],
      formData: {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        schoolName: "",
        educationLevel: "",
        accessCode: "",
      },
      showPassword: false,
      valid: false,
      verificationEmailSent: false,
      errorMessage: null,
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Adresse e-mail invalide.",
        confirmPassword: (value) => value === this.formData.password || "Les mots de passe doivent correspondre.",
        username: (value) => /^[a-zA-Z0-9._]+$/.test(value) || "Le nom d'utilisateur est invalide.",
      },
      passwordRules: [
        (v) => !!v || "Le mot de passe est requis.",
        (v) => v.length >= 8 || "Minimum 8 caractères.",
        (v) => /[A-Z]/.test(v) || "Une lettre majuscule est requise.",
        (v) => /[a-z]/.test(v) || "Une lettre minuscule est requise.",
        (v) => /\d/.test(v) || "Un chiffre est requis.",
        (v) => /[!@#$%^&*(),.?":{}|<>]/.test(v) || "Un caractère spécial est requis.",
      ],
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async register() {
      if (!this.$refs.form.validate()) return;

      const endpoint = this.getEndpointForRole();

      const payload = {
        username: this.formData.username,
        email: this.formData.email,
        password: this.formData.password,
      };

      if (this.selectedRole === "teacher") {
        payload.schoolName = this.formData.schoolName;
        payload.educationLevel = this.formData.educationLevel;
      } else if (this.selectedRole === "student") {
        payload.accessCode = this.formData.accessCode;
      }

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Erreur lors de l'inscription.");
        }

        this.verificationEmailSent = true;
        setTimeout(() => this.$router.push("/login"), 8000);
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    getEndpointForRole() {
      switch (this.selectedRole) {
        case "teacher":
          return "https://api.learnlist.fr/auth/register-teacher";
        case "student":
          return "https://api.learnlist.fr/auth/register-student";
        default:
          return "https://api.learnlist.fr/auth/register";
      }
    },
  },
};
</script>

<style scoped>
.register-page {
  background-color: #141414;
  color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.register-card {
  background-color: #1e1e1e; /* Fond sombre pour la carte */
  color: white;
  padding: 20px;
  border-radius: 12px;
}

.dark-card {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.8);
}

.role-selector {
  margin-bottom: 20px;
}

.custom-input {
  margin-bottom: 15px;
}

.dark-input {
  background-color: #1e1e1e;
}

.register-button {
  background-color: #b71c1c; /* Rouge foncé pour les boutons */
  color: white;
  margin-top: 20px;
}

.register-button:hover {
  background-color: #c62828; /* Légèrement plus clair au survol */
}

.error-message,
.verification-message {
  margin-top: 20px;
  background-color: #252525;
}

.verification-message {
  border-left: 4px solid #4caf50;
}
</style>
