<template>
  <v-app>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate color="red" size="64"></v-progress-circular>
    </v-overlay>
    <Navbar v-if="isAuthenticated" />

    <v-main :class="{'authenticated-main': isAuthenticated}">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>

    <CookieBanner v-if="showCookieBanner" @cookies-accepted="onCookiesAccepted" @cookies-declined="onCookiesDeclined" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';
import Navbar from './components/NavBar.vue';
import CookieBanner from './components/CookieBanner.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    CookieBanner,
  },
  data() {
    return {
      showCookieBanner: false,
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'loading', 'badges', 'userRole']),
  },
  watch: {
    isAuthenticated(newValue) {
      const allowedRoutes = ['LandingPage', 'ResetPassword', 'ForgotPassword', 'PolitiqueDeConfidentialite', 'Feedback', 'Register'];
      if (!newValue && !allowedRoutes.includes(this.$route.name)) {
        this.$router.push('/login');
      }
    },
    badges: {
      handler(newBadges, oldBadges) {
        if (newBadges.length > oldBadges.length) {
          const newBadge = newBadges[newBadges.length - 1];
          this.showBadgeToast(newBadge);
        }
      },
      deep: true,
    }
  },
  methods: {
    showBadgeToast(badge) {
      const toast = useToast();
      toast.success(`Félicitations ! Vous avez débloqué le badge : ${badge.name} !`, {
        position: 'top-right',
        timeout: 5000,
      });
    },
    onCookiesAccepted() {
      this.showCookieBanner = false;
      this.initGoogleAnalytics();
    },
    onCookiesDeclined() {
      this.showCookieBanner = false;
    },
    initGoogleAnalytics() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-P11Q16R736');
    },
    handleCookieConsent() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      if (cookiesAccepted === null) {
        this.showCookieBanner = true;
      } else if (cookiesAccepted === 'true') {
        this.initGoogleAnalytics();
      }
    },
    checkAuthenticationOnLoad() {
      console.log("App created, isAuthenticated:", this.isAuthenticated);
      this.$store.commit('setLoading', true);

      setTimeout(() => {
        const allowedRoutes = ['LandingPage', 'ResetPassword', 'ForgotPassword', 'PolitiqueDeConfidentialite', 'Feedback', 'Register'];
        if (!this.isAuthenticated && !allowedRoutes.includes(this.$route.name)) {
          this.$router.push('/login');
        }

        this.$store.commit('setLoading', false);
      }, 5000);
    },
  },
  created() {
    this.handleCookieConsent();
    this.checkAuthenticationOnLoad();
  }
};
</script>

<style>
/* Transitions pour les vues */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Styles globaux */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #141414;
  font-family: 'Montserrat', sans-serif;
}

#app {
  width: 100%;
  min-height: 100vh;
  background-color: #141414;
  display: flex;
  flex-direction: column;
}

.v-main {
  flex: 1;
  background-color: #141414;
}

.authenticated-main {
  padding-top: 64px;
}
</style>
